$(function () {
  home_href = $(".J_nav>ul>li:first-child>a").attr('href');
  $(".J_nav .back-home").attr('href', home_href);

  var nowPage = $('body').data('page-type');
  var prefix = nowPage == 'index' ? './' : '../'
  $.getJSON(prefix + 'config/config.json', function (option) {
    //配置SDK  参数可从对应网站根目录下的config.js文件中获取
    var yncms = new YNcms(option);
    $('.tab-module').each(function () {
      var self = $(this);
      var columnId = self.data('column-id');
      var param = {
        website_id: 1,
        pid: 7
      };
      var columnTemplate = '<%for(var index in this) {%>' +
        '<li class="<%index==0?"active":""%>" data-column-id="<%this[index].id%>">' +
        '<a href="javascript:void(0)"><%this[index].name%></a>' +
        '</li > ' +
        '<%}%>';
      yncms.getChildrenColumnByPid(param).done(function (res) {
        if (res.status) {
          // 数据处理
          var data = yncms.util.clipList(res.result.data, 0, 5);
          var html = yncms.util.renderTemplate(columnTemplate, data);
          self.find('.tab-nav').html(html);
        }
        self.find('.tab-nav li').eq(0).trigger('click');
      });
      self.on('click', '.tab-nav li', function () {
        var activeColumnId = $(this).data('column-id');
        $(this).addClass('active').siblings('li').removeClass('active');
        var infoTemplate = '<%for(var index in this) {%>' +
          '<div class="focus-slide">' +
          '<a href="<%this[index].url%>">' +
          '<img src="<%this[index].image.path%>" />' +
          '<h3 class="title"><%this[index].title%></h3>' +
          '</a>' +
          '</div>' +
          '<%}%>';
        getInfoList(infoTemplate, activeColumnId, self.find('.focus-container'), { page: 1, per_page: 3 }, { titleLength: 50 });
      });
    });
    function getInfoList(template, columnId, target, param, config) {
      yncms.getInfoByColumnId(columnId, param).done(function (res) {
        if (res.status) {
          var data = res.result.data;
          data.forEach(function (item) {
            item.title = yncms.util.intercept(item.title, config.titleLength);
            item.image = JSON.parse(item.image);
            // item.date = yncms.util.dateFormat('YYYY-mm-dd', item.date);
          })

          var html = yncms.util.renderTemplate(template, data);
          target.html(html);
        }
      });
    }
  })
});
